<template>
  <div class="upvideo">
    <!-- show-file-list:不采用原生的数据回显 -->
    <div v-show="hideUpload"> 最多可上传<span style="color: #00BC26;"> {{ limitCount }}</span> 个视频</div>
    <el-upload
      v-if="!hideUpload"
      accept="video/mp4,video/mov,video/avi,video/wmv"
      :disabled="!isShow"
      :action="uploadUrl"
      :file-list="fileList"
      :show-file-list="false"
      :limit="limitCount"
      :before-upload="beforeUpload"
      :on-success="uploadPictureSuccess"
      :on-remove="handleRemove"
      :on-change="uploadPictureChange"
      :on-error="uploadError"
      list-type="picture-card"
      class="videoUpload"
    >
      <div class="uploadIco">
        <i class="el-icon-plus"></i>
        <div class="text">上传视频</div>
      </div>
    </el-upload>
    <div style="color: #00BC26;">
      视频文件不能大于 {{ maxSize / (1024 * 1024).toFixed(2) }} MB
    </div>
    <!-- 视频文件列表 -->
    <div class="list">
      <div v-for="(item,index) in VideoArr" :key="item.id" class="list-item">
        <div class="item">
          <div class="item-name" @click="goVideo(item)">
            <i class="el-icon-circle-check" style="color: #00BC26;"></i>
            <span>{{ item.name || item }}</span>
          </div>
          <div @click="handleRemove(index)"><i class="el-icon-close" style="color: #999;"></i></div>
        </div>
      </div>
    </div>
    <el-progress v-show="!isShow" :text-inside="true" :stroke-width="16" :percentage="percentage"></el-progress>
    <!-- 视频 模态框 -->
    <el-dialog
      title="视频预览"
      :visible.sync="isVideoShow"
      width="40%"
      height="400px"
      center
    >
      <div class="big_video">
        <video
          ref="myVideo"
          controls
          autoplay
          :src="VideoItem.url"
        ></video>
      </div>
    </el-dialog>
  </div>

</template>
<script>
export default {
  props: {
    // 上传视频的URL
    uploadUrl: {
      type: String,
      default: 'https://erp.tevv.com.cn/api/upload_file/'
    },
    // 多选（支持多选择的数量）
    limitCount: {
      type: Number,
      default: 1
    },
    // 上传的视频大小 不能大于 100MB
    maxSize: {
      type: Number,
      default: 104857600
    },
    // 存放 父组件传递过来的 视频文件(回显)
    videoList: {
      type: Array,
      default: function () {
        return [] // 返回一个空数组作为默认值
      }
    }
  },
  data () {
    return {
      hideUpload: false,
      fileList: [],
      VideoArr: [], // 存放自定义展示视频的数据
      isVideoShow: false, // 控制模态框是否显示
      VideoItem: {}, // 模态框当前播放的内容
      percentage: 0,
      timer: null, // 存放定时器
      isShow: true // 控制 当前是否开启 上传视频的
    }
  },
  watch: {
    VideoArr (value) {
      // 根据文件列表的长度是否达到了限制数量，来决定是否隐藏上传控件
      this.hideUpload = value.length >= this.limitCount
      // 将最新数据传递给父组件
      this.$emit('handleVideo', value)
    },
    // 监听 父组件传递过来的 数据
    videoList: {
      handler (newVal, oldVal) {
        for (let i = 0; i < newVal.length; i++) {
          this.VideoArr.push({
            name: newVal[i],
            url: newVal[i]
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    // 上传文件之前的钩子
    beforeUpload (file) {
      const isJPG =
        file.type === 'video/mp4' ||
        file.type === 'video/mov' ||
        file.type === 'video/wmv' ||
        file.type === 'video/avi'
      if (!isJPG) {
        this.$message.error('只支持格式为MP4/MOV/AVI/WMV的视频文件！')
        return false
      } else if (file.size > this.maxSize) {
        this.$message.error('视频文件不能超过限制大小')
        return false
      }
      console.log('上传文件之前的钩子', file)
      // 正在上传，还没上传结束之前不能再一次上传视频
      this.isShow = false
      // 开启进度条
      this.percentage = 0
      this.timer = setInterval(() => {
      // 每隔一秒更新计数值
        this.percentage++
      }, 1000)
    },
    // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
    uploadPictureChange (file, fileList) {
      console.log('上传成功和上传失败时都会被调用', file)
      // 根据文件列表的长度是否达到了限制数量，来决定是否隐藏上传控件
      this.hideUpload = fileList.length >= this.limitCount
    },
    // 文件上传成功时的钩子
    uploadPictureSuccess (res, file) {
      var _this = this
      // console.log('文件上传成功时的钩子', res, file);
      if (file.status === 'success') {
        this.$message.success('上传成功')
      }
      // 成功后处理数据
      this.VideoArr.push({
        name: file.name,
        url: res.data.image_url
      })
      this.percentage = 100
      clearInterval(this.timer)
      // 上传结束
      this.isShow = true
    },
    // 上传失败的 钩子
    uploadError (file) {
      this.$message.error('上传失败，请稍后再试')
      this.percentage = 0
      this.timer = null
      clearInterval(this.timer)
      // 上传结束
      this.isShow = true
    },
    // 点击视频文件 模态框的打开
    goVideo (item) {
      this.isVideoShow = true
      this.VideoItem = item
    },
    // 删除 视频文件
    handleRemove (index) {
      this.VideoArr.splice(index, 1)
    }
  }

}
</script>
<style lang="scss" scoped>
::v-deep .el-upload__input{
  display: none !important;
}
::v-deep .is-success{
  position: relative;
}
::v-deep .el-upload--picture-card{
  width: 100px;
  height: 100px;
  border:none;
  background-color:#fff;
}
::v-deep .videoUpload{
  position: relative;
}
::v-deep .avatar{
  position: absolute;
  width: 148px;
  top: 35px;
  left: 0;
}
::v-deep .el-upload-list--picture-card{
  position: absolute;
  z-index: 999;

}
::v-deep .el-upload-list--picture-card .el-upload-list__item{
  background: transparent;
}
::v-deep .el-upload-list__item-thumbnail{
  display: none;
}
.upvideo{
  margin: 0 15px;
  .uploadIco {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 17px !important;
    .text{
      font-size: 14px;
    }
  }
  .list{
    padding: 10px 0;
    .list-item{
      width: 250px;
      padding-top: 10px;
      color: #666;
      .item{
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        .item-name{
          width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .el-icon-close{
          cursor: pointer;
        }
      }
    }
  }
  .big_video{
    width: 100%;
    height: 100%;
    video{
      width: 100%;
      height: 100%;
    }
  }
}

</style>
