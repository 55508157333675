<template>
  <div class="detail">
    <div class="header">
      <a-button @click="cancel" style="margin-right: 10px">取消</a-button>
      <a-button type="primary" @click="save" :loading="loadingSave">保存</a-button>
    </div>

    <div class="content">
      <div>
        <a-form-model
          ref="ruleForm"
          :model="detail"
          :rules="rules"
          :label-col="{ span: 2 }"
          :wrapper-col="{ span: 22 }"
        >
          <a-form-model-item prop="advisory_type" label="标题">
            <a-tag
              :color="detail.advisory_type === item.value ? '#55b98a' : ''"
              v-for="item in advisory_type"
              :key="item.value"
              @click="detail.advisory_type = item.value"
              style="cursor: pointer"
            >{{ item.name }}</a-tag>
          </a-form-model-item>

          <a-form-model-item prop="title" label="标题">
            <a-input v-model="detail.title" placeholder="标题" />
          </a-form-model-item>

          <a-form-model-item prop="title_image" label="封面图片">
            <a-upload
              accept="image/*"
              :before-upload="upload"
              :disabled="uploading"
              :remove="removeUpload"
              class="avatar-uploader"
              :show-upload-list="false"
            >
              <div class="upload-box">
                <img :src="detail.title_image" alt v-if="detail.title_image" />
                <div v-else class="upload-content">
                  <a-icon type="upload" style="font-size: 20px;padding-bottom: 5px"></a-icon>上传封面
                </div>
              </div>
            </a-upload>
            <p class="upload-tips">上传一张，建议尺寸300px * 200px，大小不超过5MB</p>
          </a-form-model-item>

          <a-form-model-item prop="video" label="视频展示" v-if="detail.advisory_type === '3'">
            <!-- <a-upload
              :before-upload="uploadVideo"
              :disabled="uploadingVideo"
              :remove="removeVideoUpload"
              class="avatar-uploader"
            >
              <div class="upload-box">
                <div class="upload-content">
                  <a-icon type="upload" style="font-size: 20px;padding-bottom: 5px"></a-icon>上传视频
                </div>
              </div>
            </a-upload> -->
            <Upvideo
              :limit-count="1"
              :video-list="videoList"
              @handleVideo="handleVideo"
            ></Upvideo>
          </a-form-model-item>

          <a-form-model-item prop="sort" label="排序">
            <a-input v-model.number="detail.sort" placeholder="请填写排序" type="number" />
          </a-form-model-item>

          <a-form-model-item prop="ad_type" label="内容类型">
            <a-radio-group name="ad_type" v-model="detail.ad_type">
              <a-radio value="2">文章</a-radio>
              <a-radio value="1">外部链接</a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item prop="href" label="链接地址" v-if="detail.ad_type === '1'">
            <a-input v-model="detail.href" placeholder="请输入链接地址，以http开头" />
          </a-form-model-item>

          <a-form-model-item prop="content" label="内容" v-else>
            <!-- <Edit v-model="detail.content" style="width: 100%" v-if="isInit"></Edit> -->
            <Edit v-model="detail.content" style="width: 100%" v-if="isInit" @change="handleChange"></Edit>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import { getArticleDetail, updateArticle, addArticle, getNewsCategory } from '@/api/admin'
import upload from '@/plugins/upload'
import Upvideo from '@/components/Upvideo'

import Edit from '@/components/Editor'
import { getUserInfo } from '@/utils/auth'

export default {
  components: {
    Edit,
    Upvideo
  },
  data () {
    return {
      videoList: [], // 存放 编辑回显的 视频文件

      isInit: false,
      uploading: false,
      uploadingVideo: false,
      detail: {
        code: '',
        title: '',
        title_image: '',
        content: '',
        href: '',
        ad_type: '2', // 新闻类型 1:外部链接 2:文章
        advisory_type: '1', // 类型 1 公司新闻 2 行业动态 3 场景案例
        sort: 1, // 排序
        create_by: getUserInfo(), // "当前用户名"
        video: ''
      },
      rules: {
        advisory_type: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        title: [
          { required: true, message: '请填写标题', trigger: 'change' }
        ],
        title_image: [
          { required: true, message: '请上传封面图片', trigger: 'change' }
        ],
        video: [
          { required: true, message: '请上传视频', trigger: 'change' }
        ],
        sort: [
          { required: true, message: '请填写排序', trigger: 'change' }
        ],
        content: [
          { required: true, message: '请填写内容', trigger: 'change' }
        ],
        ad_type: [
          { required: true, message: '请填写内容', trigger: 'change' }
        ],
        href: [
          { required: true, message: '请填写链接地址', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              if (this.detail.ad_type === '1') {
                if (!value.startsWith('http')) {
                  return callback(new Error('链接地址必须以http开头'))
                }
              }
              callback()
            },
            trigger: 'change'
          }]
      },
      loadingSave: false,
      advisory_type: []
    }
  },
  created () {
    this.getType()
    this.getData()
  },
  methods: {
    handleChange (e) {
      var regex = /<xmp>([\s\S]*?)<\/xmp>/i
      var match = e.match(regex)
      if (match) {
        var extractedHtml = match[1]
        this.detail.content = extractedHtml
      }
    },
    // 商品视频列表
    handleVideo (list) {
      this.detail.video = list[0]?.url || ''
    },
    async getType () {
      const data = await getNewsCategory({ classify_type: ['1'] }) // 1:新闻资讯
      this.advisory_type = (data || []).map(item => (
        {
          name: item.name,
          value: item.code
        }
      ))
    },
    getData () {
      if (this.$route.query.code) {
        getArticleDetail({
          code: this.$route.query.code
        }).then(res => {
          this.detail = res.cur
          if (res.cur.video) {
            this.videoList = []
            this.videoList.push(res.cur.video)
          }
          this.isInit = true
        }).catch(err => {
          this.$message.error(err.msg)
        })
      } else {
        this.isInit = true
      }
    },
    save () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loadingSave = true
          if (this.$route.query.code) {
            updateArticle(this.detail).then(res => {
              this.loadingSave = false
              this.$message.success('保存成功')
              this.$router.back()
            }).catch(err => {
              this.loadingSave = false
              this.$message.error(err.msg)
            })
          } else {
            addArticle(this.detail).then(res => {
              this.loadingSave = false
              this.$message.success('保存成功')
              this.$router.back()
            }).catch(err => {
              this.loadingSave = false
              this.$message.error(err.msg)
            })
          }
        } else {
          this.$message.error('请检查表单数据')
        }
      })
    },
    cancel () {
      this.$router.back()
    },
    upload (file) {
      return new Promise((resolve, reject) => {
        if (file.size > 5 * 1024 * 1024) { // 5MB
          reject()
          return this.$message.error('文件大小超过限制')
        }

        this.uploading = true
        upload(file).then(url => {
          this.detail.title_image = url
          resolve(url)
          this.uploading = false
        }).catch(err => {
          reject()
          this.$message.error(err.msg)
          this.uploading = false
        })
      })
    },
    removeUpload () {
      this.detail.title_image = ''
    },
    uploadVideo (file) {
      return new Promise((resolve, reject) => {
        if (file.size > 50 * 1024 * 1024) {
          reject()
          return this.$message.error('文件大小超过限制')
        }

        this.uploadingVideo = true
        upload(file).then(url => {
          this.detail.video = url
          resolve(url)
          this.uploadingVideo = false
        }).catch(err => {
          reject()
          this.$message.error(err.msg)
          this.uploadingVideo = false
        })
      })
    },
    removeVideoUpload () {
      this.detail.video = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .header {
    position: fixed;
    background: #fff;
    right: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60px;
    padding: 0 30px;
    z-index: 1;
  }

  .content {
    padding: 30px;
    padding-top: 90px;
  }

  .upload-box {
    height: 100px;
    width: 100px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .upload-content {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }

  .upload-tips {
    color: #999;
  }
}
</style>
